import { Typography, Box, Link, Button, List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import locationImg from '../images/locations.png'
import rankImg from '../images/rankings.gif'
import loginImg from '../images/hordelogin.gif'
import rconImg from '../images/rcon.gif'
import mapsImg from '../images/maps.png'
import botImg from '../images/bot.png'
import PH1 from '../images/PHorde2.png'
import PH2 from '../images/PHorde3gold.png'
import selectorImg2 from '../images/mapSelector2.png'
import selectorImg from '../images/mapSelector.png'
import hordeBotHelp from '../images/hordeBotHelp.png'
import React from 'react';
import Contact from '../components/Contact'

const useStyles = makeStyles({
    marginAutoItem: {
        margin: 'auto',
        display: 'flex',
    },
    prodImg: {
        maxHeight: '50vh'
    },
    prodIcon: {
        maxHeight: '10vh'
    },
    bigImg: {
        maxHeight: '75vh'
    },
    bigTitle: {
        textDecoration: 'underline',
    },
    descTxt: {
        fontFamily: 'Roboto'
    }
})

export default function Description() {

    const classes = useStyles()

    const getPings = () => {
        if (typeof window !== `undefined`) window.open('https://cloudpingtest.com/vultr')
    }

    const getRanks = () => {
        if (typeof window !== `undefined`) window.open('https://pavlovhorde.com/scores')
    }

    return (
        <Box>
            <Typography className={classes.bigTitle} variant="h4">Features</Typography>
            <Box pt={2}>
                <Typography pt={3} variant="h5">{`Specs`}</Typography>
                <Typography className={classes.descTxt} variant="body2">Once ordered and paid for, the server will automatically install all necessary software and features below, and be ready to use 
                </Typography>
                <Typography variant="body1">within 15 minutes.</Typography>
                
                
            </Box>
            <Box pt={2}>
                <Box>
                    <Typography pt={3} variant="h5">Web Server Manager</Typography>
                    <Typography className={classes.descTxt} variant="body2">The Web Horde Server Manager portal allows you to manage everything for your server, including:</Typography>
                </Box>
                <Box>
                <ul>
                    <li>Change Server Name</li>
                    <li>Change Server PIN</li>
                    <li>Change Game Time</li>
                    <li>Set Discord Webhook (notify your friends in discord of server activity!)</li>
                    <li>Set and Change Horde Home Settings (customise your in-game Home map!)</li>
                    <li>Change the map rotation and game modes (Choose from all available shack maps!)</li>
                </ul>
                </Box>
                <Typography className={classes.descTxt} variant="body2">
                All changes are saved directly, immediately, as many times as you want.
                </Typography>
                <Box pt={2}>

                    <img className={`${classes.prodImg} ${classes.marginAutoItem}`} src={loginImg}></img>

                </Box>
            </Box>
            <Box pt={2}>
                <Typography pt={3} variant="h5">Hort Discord Bot</Typography>
                <Typography className={classes.descTxt} variant="body2">Under Maintenance, coming back soon! </Typography>
                
            </Box>
            
            <Box pt={2}>
                <Typography pt={3} variant="h5">Global Hosting - Lowest Pings!</Typography>
                <Typography className={classes.descTxt} variant="body2">Choose from 17 locations around the world to get the best pings for play.
                </Typography>

                <Box pt={2}>

                    <img className={`${classes.prodImg} ${classes.marginAutoItem}`} src={locationImg}></img>
                    <Box pt={2}>
                        <Button className={classes.marginAutoItem} onClick={getPings} variant="contained" color="secondary">
                            Find Your Best Ping
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box pt={2}>
                <Typography pt={3} variant="h5">Web RCON Tool</Typography>
                <Typography className={classes.descTxt} variant="body2">The Web RCON tool allows server owners to issue commands to a server during a live game. You can kick a player, reset the match, switch to a different map (any map, even if its not in the server rotation!), set a custom player skin, give items (including new WW2 guns and items!), and more.
                </Typography>
                <Box pt={2}>

                    <img className={`${classes.prodImg} ${classes.marginAutoItem}`} src={rconImg}></img>

                </Box>
            </Box>
            <Box pt={2}>
                <Typography pt={3} variant="h5">Updated Maps - Ready to Use</Typography>
                <Typography className={classes.descTxt} variant="body2">All Quest maps are updated regularly and available for map rotation and RCON SwitchMap usage. If you can't find a map <Link href="https://pavlovhorde.com/mapsList" target="_blank">here</Link> then please contact Chingghis on the <Link href="https://discord.gg/ajyWn6YWWm" target="_blank">Horde Discord</Link>.
                </Typography>
                <Box pt={2}>

                    <img className={`${classes.prodImg} ${classes.marginAutoItem}`} src={mapsImg}></img>

                </Box>
            </Box>

            <img className={`${classes.prodIcon} `} src={PH1}></img>
            <img className={`${classes.prodIcon} `} src={PH2}></img>
        </Box>
    )
}