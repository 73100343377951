import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { navigate } from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'
import Description from '../components/Products'
import Contact from '../components/Contact';
import TeamsList from '../components/TeamsList'

const ProductsPage = ({ location }) => {

  const handleBack = () => {
    navigate("/")
  }

  const pageTitle = "Pavlov Champions League"

  const pageDesc = "Play Competitive!"

  return (
    <Layout pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <IconButton
        onClick={handleBack}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h3" style={{ color: "white" }}>
        {pageDesc}
      </Typography>
      <Box display="flex" justifyContent="space-evenly">
        <TeamsList />

      </Box>

    </Layout>
  )
}

export default ProductsPage
