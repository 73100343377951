import { Typography, Box, Button} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import * as api from '../api/calls'


const useStyles = makeStyles({
    marginAutoItem: {
        margin: 'auto',
        display: 'flex',
    },
    prodImg: {
        maxHeight: '50vh'
    },
    prodIcon: {
        maxHeight: '10vh'
    },
    bigImg: {
        maxHeight: '75vh'
    },
    bigTitle: {
        textDecoration: 'underline',
    },
    descTxt: {
        fontFamily: 'Roboto'
    }
})

export default function TeamsDesc() {

    const classes = useStyles()

    const [teamsData, setTeamsData] = useState({teamsArr:[]})
    
    const getTeams = async () =>{
        const teamsJSON = await api.getTeamsData()
        setTeamsData(teamsJSON)
      // console.log(teamsJSON)
    }

    useEffect(() => {
        getTeams()

    }, [])

    return (
        <Box pt={2}>
            <Box pb={2}>
            <Button variant="contained" color="secondary" href="https://discord.gg/nU46d9PGXU">Join The League!</Button>
            </Box>
            <Typography className={classes.bigTitle} variant="h4">Teams</Typography>
            {teamsData.teamsArr.map(t => (
                <Box key={t.teamName}>
                    <Typography>{t.teamName}</Typography>
                    <img height='100px' width='100px'src={t.remoteURL}/>
                </Box>
            ))}
        </Box>
    )
}